import { Alert } from "@mui/material";
import useAuth from "src/hooks/useAuth";
import { setSession } from "src/utils/jwt";

const ImpersonationBanner = () => {
  const { user, isAdmin } = useAuth();
  const isImpersonate = !isAdmin ? localStorage.getItem("isImpersonate") : null;

  const goBackToAdmin = () => {
    const adminToken = localStorage.getItem("adminToken");
    localStorage.setItem("isAdmin", true);
    localStorage.removeItem("isImpersonate");
    setSession(adminToken);
    const source = sessionStorage.getItem("impersonationSource");
    window.location = `${window.origin}${source ? source : "/auth/login"}`;
  };
  return isImpersonate ? (
    <Alert severity="info">
      Heads up! You are now logged in as {user.username}{" "}
      <strong
        onClick={goBackToAdmin}
        style={{
          textDecoration: "none",
          cursor: "pointer",
        }}
      >
        Click here
      </strong>{" "}
      ,to go back admin account
    </Alert>
  ) : null;
};

export default ImpersonationBanner;
